.header {
  /* background-color: var(--anakiwa-b100); */
  clear: both;
  padding: 20px 0;
  position: sticky;
  width: 100%;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: 500;
  box-shadow: 0px 1px 5px -4px #000000  ;
}

.header::before {
  position: relative;
  content: ' ';
  height: 500px;
}

/* .header::after {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  background-color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.header .logo {
  display: inline-block;
}

.header .logo img {
  height: 50px;
}

.header .navigation {
  float: right;
}

.header .navigation header nav ul li {
  padding-left: 30px;
  line-height: 53px;
}

.header .navigation header nav ul li a {
  font-weight: 500;
  letter-spacing: .5px;
}

.header .navigation header nav ul li a.active {
  color: var(--prusian-blue-b300);
}

.menu-btn {
  width: 30px;
  height: 30px;
}

.menu-btn img {
  width: 30px;
  height: 30px;
}

.hamburger-menu {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5%;
  cursor: pointer;
}