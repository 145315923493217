body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

html {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  display: inline-flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  border-radius: none;
}

textarea,
input,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="mail"],
input[type="search"] {
	-webkit-appearance: none;
	border-radius: 0;
}