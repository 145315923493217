/**
  * Generic class
***/

:root {
  --white-b50: #FFFFFF;
  --white-b75: #FFFFFF;
  --white-b100: #FFFFFF;
  --white-b200: #FFFFFF;
  --white-b300: #FFFFFF;
  --white-b400: #B3B3B3;
  --white-b500: #9C9C9C;

  --black-b50: #E6E6E6;
  --black-b75: #969696;
  --black-b100: #6B6B6B;
  --black-b200: #2B2B2B;
  --black-b300: #000000;
  --black-b400: #000000;
  --black-b500: #000000;

  --anakiwa-b50: #F4F8FF;
  --anakiwa-b75: #D0E3FF;
  --anakiwa-b100: #BDD8FF;
  --anakiwa-b200: #A0C7FF;
  --anakiwa-b300: #8DBBFF;
  --anakiwa-b400: #6383B3;
  --anakiwa-b500: #56729C;

  --prusian-blue-b50: #E6EAEE;
  --prusian-blue-b75: #96A7BB;
  --prusian-blue-b100: #6B829F;
  --prusian-blue-b200: #2B4D75;
  --prusian-blue-b300: #002859;
  --prusian-blue-b400: #001C3E;
  --prusian-blue-b500: #001836;
}

html {
  scroll-behavior: smooth;
}

.clear {
  clear: both;
}

.container {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.section {
  margin: 100px 0;
  padding: 100px 0;
}

.section-small {
  margin: 0;
  padding: 0;
}

ul.block {
  display: block;
  list-style: disc;
}

.text-prusian-blue-b300 {
  color: var(--prusian-blue-b300);
}

.text-white-b300 {
  color: var(--white-b300);
}

.bg-prusian-blue-b300 {
  background-color: var(--prusian-blue-b300);
}

.center-y {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.center-x {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.center-xy {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h1.title-xl {
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
}

h2.title {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
}

p.p-m {
  font-size: 25px;
  line-height: 40px;
  font-weight: 300;
}

a.p-m {
  font-size: 25px;
  line-height: 40px;
  font-weight: 300;
}

li.li-m {
  font-size: 25px;
  line-height: 40px;
  font-weight: 300;
}

p.p-s {
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
}

p.p-xs {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}

a.p-xs {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-bold, h1.text-bold, p.text-bold {
  font-weight: 500;
}

.text-italic {
  font-style: italic;
}

.generic-banner {
  height: 400px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.generic-banner .text-box {
  position: absolute;
  text-align: center;
}

.generic-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(0, 40, 89, 0.595) 0%, rgba(0, 40, 89, 0.85) 37.85%, rgba(0, 40, 89, 0.85) 60.85%, rgba(0, 40, 89, 0.595) 100%)
}

.generic-banner-compta {
  background-image: url(../../ressource/background-comptabilite.png);
}

.generic-banner-contact {
  background-image: url(../../ressource/background-contact.png);
}

.generic-banner-about {
  background-image: url(../../ressource/background-about.png);
}

/**
  * Home.js
***/

.first-home-section .container {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.first-home-section .container-left {
}

.first-home-section .container-left h1 .small-caps {
  font-size: 65px;
}

.first-home-section .container-left .sep {
  position: relative;
  width: 50%;
  height: 3px;
  background-color: var(--prusian-blue-b300);
  margin: 30px 0;
}

.first-home-section .container-right {
  position: relative;
}

.first-home-section .container-right .center-x {
  height: 400px;
  float: left;
  clear: both;
}

.first-home-section .container-right img {
  max-height: 400px;
  width: 100%;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.second-home-section .grid-3 {
  padding-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10%;
  grid-row-gap: 0px;
  margin: 0 10%;
}

.second-home-section .grid-3 .grid-elem {
  /* background-color: yellow; */
}

.second-home-section .grid-3 .grid-elem img {
  width: 70%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.second-home-section .grid-3 .grid-elem p {
  text-align: center;
  padding: 10px 0;
}

.third-home-section {
  padding: 80px;
}

.third-home-section .grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 0px;
  max-height: 60px;
}

.third-home-section .grid-4 .grid-elem {
  max-height: 60px;
  position: relative;
}

.third-home-section .grid-4 .grid-elem img {
  max-width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 60px;
}

.section-home-fourth .grid-2 {
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  grid-row-gap: 0px;
  margin: 0 5%;
}

.section-home-fourth .grid-2.grid-reverse {
  padding-top: 100px;
  grid-template-columns: 0.4fr 0.6fr;
}

.section-home-fourth .grid-2 .grid-elem:nth-child(1) {
  padding-right: 100px;
}

.section-home-fourth .grid-2 .grid-elem p.p-m {
  padding-top: 50px;
}

.section-home-fourth .grid-2 .grid-elem img {
  width: 70%;
}

.section-home-fifth {
  position: relative;
}

.arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.arrow-left {
  float: left;
}

.arrow-right {
  float: right;
}

.carousel-review {
  width: 95%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.carousel-review .carousel-container {
  height: 100%;
  width: calc(100% / 3);
  position: relative;
  transition: .33s;
  display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
}

.carousel-review .carousel-container .carousel-elem {
  position: relative;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
}

.carousel-review .carousel-container .carousel-elem p {
  margin: 0 5%;
}

.carousel-review .carousel-container .carousel-elem img {
  width: 50px;
  height: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 20px;
}

/**
  * About.js
***/

.section-about-first .text-box {
  max-width: 60%;
  margin: 0 auto;
  padding-top: 50px;
}

.section-about-second .container {
}

.section-about-second .ml {
  margin-left: 10%;
}

.section-about-second .grid-2 {
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  grid-row-gap: 0px;
  margin: 0 10%;
  margin-top: 50px;
}

.section-about-second .grid-2 .grid-elem {
  margin-top: 50px;
}

.section-about-second .grid-2 .text-box {
  max-width: 70%;
}

.section-contact-first .grid-2 {
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  grid-row-gap: 0px;
  margin-top: 50px;
}

.section-contact-first .grid-2 .grid-elem .mt {
  padding-top: 40px;
}

.section-contact-first .input-container-2 {
  display: grid;
  grid-template-columns:0.5fr 0.5fr;
  grid-column-gap: 10%;
}

.section-contact-first .grid-1:nth-child(1) {
  margin-left: 0;
  margin-top: 0;
}

.section-contact-first .grid-1 {
  margin-left: 5%;
  position: relative;
  margin-top: 30px;
}
.section-contact-first .grid-1 .map {
  margin-left: 5%;
}
.section-contact-first .grid-1 img {
  float: left;
  height: 40px;
  width: 40px;
}

.section-contact-first .grid-1 a, .section-contact-first .grid-1 p {
  margin-left: 20px;
}

.input-container {
  position: relative;
  margin-top: 20px;
}

.input-container input {
  font-size: 16px;
  padding: 10px 0;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--prusian-blue-b300);
  outline: none;
}

.input-container label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  color: var(--prusian-blue-b300);
  pointer-events: none;
  transition: all 0.2s ease;
}

.input-container textarea {
  font-size: 20px;
  padding: 10px 0;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--prusian-blue-b300);
  outline: none;
  resize: none;
  grid-row: 300;
  font-family: 'Roboto', sans-serif;
}

.input-container input:focus ~ label,
.input-container input.active ~ label,
.input-container textarea:focus ~ label,
.input-container textarea.active ~ label {
  top: -20px;
  font-size: 16px;
  color: var(--prusian-blue-b300);
}

.section-contact-first .btn {
  position: relative;
  margin-top: 50px;
}

.section-contact-first button {
  outline: none;
  border: 0;
  background-color: var(--prusian-blue-b300);
  color: var(--white-b300);
  padding: 15px 25px;
  font-size: 20px;
  cursor: pointer;
  transition: .33s;
  border: 1px solid var(--prusian-blue-b300);
}

.section-contact-first button:hover {
  background-color: var(--white-b300);
  color: var(--prusian-blue-b300);
  border-radius: 15px;
}

.markercluster-map {
  height: 300px;
  width: 70%;
  margin: 0 auto;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.container-wheel {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.container-wheel .text-box p {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.container-wheel .rounded-svg {
  width: 80%;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
}

.container-wheel .rounded-svg svg {
  width: 100%;
  height: auto;
}

.compta-grid-elem {
  padding: 100px 0;
  /* background-color: #969696; */
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 0px;
}

.compta-grid-elem-left {
  grid-template-columns: 0.4fr 0.6fr;
}

.compta-grid-elem-right {
  grid-template-columns: 0.6fr 0.4fr;
}

.compta-grid-elem .compta-grid-elem-text {
  padding: 100px 0;
}

.compta-grid-elem .compta-grid-elem-text p {
  padding-top: 40px;
}

.compta-grid-elem .compta-grid-elem-img {
  width: 100%;
  height: 100%;
  background-color: var(--prusian-blue-b300);
  background-size: cover;
  position: relative;
  background-position: center center;
}

.compta-grid-elem .compta-grid-elem-img::after {
  width: 100%;
  height: 100%;
  position: absolute;
  content: ' ';
  background-color: rgba(0, 40, 89, 0.4);
  top: 0;
  left: 0;
  z-index: 100;
}

.widget {
  width: 60px;
  height: 60px;
  background-color: var(--prusian-blue-b300);
  border-radius: 50%;
  position: fixed;
  right: 5%;
  bottom: 10%;
  z-index: 1000;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(255,255,255,1);
  box-shadow: 0px 0px 5px 0px rgba(255,255,255,1);
}

.widget img {
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}

@media screen and (max-width: 1250px) {
  body {
  }
  .container {
    width: 90%;
  }
  h1.title-xl {
    font-size: 40px;
  }
  .first-home-section .container-left h1 .small-caps {
    font-size: 50px;
  }
  p.p-m {
    font-size: 20px;
  }
  a.p-m {
    font-size: 20px;
  }
  li.li-m {
    font-size: 20px;
  }
  .carousel-review .carousel-container {
    width: calc(100% / 2);
  }
  .footer .responsive-hide {
    display: none;
  }
  .footer .footer-grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .input-container label {
    font-size: 18px;
  }
  .section-contact-first button {
    font-size: 18px
  }
  .input-container input:focus ~ label,
  .input-container input.active ~ label,
  .input-container textarea:focus ~ label,
  .input-container textarea.active ~ label {
    top: -20px;
    font-size: 15px;
    color: var(--prusian-blue-b300);
  }
  p.p-s {
    font-size: 18px;
  }
  a.p-xs {
    font-size: 16px;
  }
  p.p-xs {
    font-size: 16px;
  }
  .footer .border {
    padding: 50px 0 !important;
  }
  .footer-grid-3 .footer-grid-elem-title {
    height: 50px !important;
  }
  .footer-swap img {
    height: 50px !important;
    position: relative;
  }
  .footer-grid-3 .footer-grid-elem {
    padding: 5px 0 !important;
  }
  .bttomfooter-grid-3 {
    /* grid-template-columns: 1fr !important; */
  }
}

@media screen and (max-width: 950px) {
  .section {
    margin: 0;
  }
  .first-home-section {
    background-image: url(../../ressource/home-image-responsive.png);
    background-size: cover;
    position: relative;
    background-position: center center;
    height: calc(100vh - 74px);
    padding: 0;
  }
  .first-home-section::before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255, 0.3);
    content: ' ';
  }
  .first-home-section .container {
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
  }
  .first-home-section .container .center-y {
    top: 0;
    transform: none;
  }
  .first-home-section .container-right {
    display: none;
  }
  .first-home-section .container-left {
    margin: 0 auto;
    width: 70%;
    text-align: center;
  }
  .first-home-section .container-left .sep {
    margin: 30px auto;
  }
  .second-home-section .grid-3 {
    grid-column-gap: 2%;
    margin: 0;
  }
  .third-home-section {
    padding: 80px 0;
  }
  .section-home-fourth .grid-2 {
    margin: 0;
  }
  .section-home-fourth .grid-2 .grid-elem:nth-child(1) {
    padding-right: 0;
  }
  .section-home-fourth .grid-2 .grid-elem img {
    width: 90%;
  }
  .carousel-review {
    width: 100%;
  }
  .footer-contact form {
    float: none !important;
    text-align: center;
  }
  .footer-contact form input {
    font-size: 18px !important;
    width: 60% !important;
    display: inline-block;
    max-width: 400px;
  }
  .footer-contact form button {
    font-size: 18px !important;
    padding: 10px 25px !important;
    margin-left: 0 !important;
    margin-top: 20px;
    left: 50%;
    position: relative;
    transform: translate(-50%);
    display: block;
  }
  .footer .text-right {
    text-align: center !important;
  }
  .footer .text-left {
    text-align: center !important;
  }
  .bttomfooter-grid-3 {
    grid-template-columns: 1fr !important;
    padding: 30px 0 !important;
  }
  .compta-grid-elem .compta-grid-elem-img {
    height: 200px;
    max-width: 800px;
    order: 1;
    margin: 0 auto;
  }
  .compta-grid-elem .compta-grid-elem-text {
    padding: 50px 0 0;
    order: 2;
  }
  .compta-grid-elem {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
  }
  .section-about-second .grid-2 {
    margin: 50px 0 0;
  }
  .section-about-second .grid-2 .text-box {
    max-width: 90%;
  }
  .container-wheel {
    grid-template-columns: 1fr;
    margin: 100px auto;
  }
  .container-wheel .text-box {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .container-wheel .rounded-svg {
    margin-top: 50px;
  }
}

@media screen and (max-width: 780px) {
  .carousel-review .carousel-container {
    width: calc(100% / 1);
  }
  .section-about-first .text-box {
    max-width: 80%;
  }
}

@media screen and (max-width: 700px) {
  h1.title-xl {
    font-size: 30px;
  }
  .first-home-section .container-left h1 .small-caps {
    font-size: 40px;
  }
  .first-home-section .container-left {
    width: 100%;
  }
  .header {
    padding: 0 !important;
  }
  .header .logo {
    left: calc(50% + 4px);
    transform: translateX(-50%);
    position: relative;
    height: 30px;
  }
  .header .logo img {
    height: 30px !important;
  }
  .header .container {
    width: 100%;
    padding: 20px 0;
  }
  .hamburger-menu {
    display: block !important;
  }
  .header .navigation {
    /* display: none; */
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    width: 100%;
    box-shadow: 0px 1px 5px -4px #000000;
    transition: .33s;
    padding: 73px 0 10px;
  }
  .header .navigation-hide {
    transform: translateY(-100%);
  }
  .header .navigation-show {
    transform: translateY(0%);
  }
  .header .navigation ul {
    display: block;
  }
  .header .navigation ul li {
    padding-left: 0 !important;
    line-height: 40px !important;
    text-align: center;
  }
  .header .navigation header nav ul li a {
    font-weight: 400;
  }
  h2.title {
    font-size: 25px;
  }
  .third-home-section {
    padding: 30px 0;
  }
  .section-home-fourth .grid-2 {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .section-home-fourth .grid-2 .grid-elem img {
    width: 50%;
    margin-top: 50px;
  }
  .section-home-fourth .grid-2 .grid-elem img.center-xy {
    left: 0;
    top: 0;
    transform: none;
  }
  p.p-m {
    font-size: 18px;
  }
  a.p-m {
    font-size: 18px;
  }
  li.li-m {
    font-size: 18px;
  }
  .input-container label {
    font-size: 16px;
  }
  .section-contact-first button {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .input-container input:focus ~ label,
  .input-container input.active ~ label,
  .input-container textarea:focus ~ label,
  .input-container textarea.active ~ label {
    top: -20px;
    font-size: 14px;
    color: var(--prusian-blue-b300);
  }
  .rounded-svg {
    width: 60%;
  }
  .generic-banner {
    height: 200px;
  }
  .section-contact-first .grid-1 img {
    width: 30px;
    height: 30px;
    top: 5px;
    position: relative;
  }
  .section-contact-first .grid-2 {
    grid-template-columns: 1fr;
  }
  .section-contact-first .grid-1 .map {
    margin: 0;
  }
  .section-contact-first .grid-1 {
    margin: 30px 0 0 ;
  }
  .section-contact-first .grid-2 .grid-elem:nth-child(2) {
    margin-top: 50px;
  }
  .section-about-second .grid-2 {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .section-about-second .grid-2 .grid-elem:nth-child(even) {
    margin-top: 20px;
  }
  .section-about-second .grid-2 .grid-elem:nth-child(odd) {
    margin-top: 40px;
  }
  .section-about-second .grid-2 .text-box {
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .rounded-svg {
    width: 80%;
  }
  .section-about-first .text-box {
    max-width: 100%;
  }
  .widget {
    bottom: 5%;
  }
}

/* .section-about-second .grid-2 .grid-elem:nth-child(1) {
  background-color: yellow;
}

.section-about-second .grid-2 .grid-elem:nth-child(2) {
  background-color: red;
} */

